import React from "react";
import { css } from "linaria";
import SlideInContainer from "components/common/SlideInContainer";
import { StaticImage } from "gatsby-plugin-image";
import {
  Title,
  TitleMed,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";

const sectionContainer = css`
  background: white;
`;

const contentContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: var(--max-width);
  width: 100%;
  padding: 70px 40px 122px 40px;
  margin: 0 auto;

  @media (max-width: 600px) {
    padding: 50px 20px 50px 20px;
  }

  div {
    width: 100%;
  }
`;

const titleContainer = css`
  width: 100%;

  > div {
    width: 100%;

    div {
      display: flex;
      justify-content: center;

      @media (max-width: 800px) {
        justify-content: flex-start;
      }
    }
  }
`;

const titleClass = css`
  text-align: center;
  @media (max-width: 800px) {
    text-align: left;
  }
`;

const imageContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  column-gap: 25px;
  margin-top: 72px;

  > div {
    width: unset;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 54px;
    margin-top: 50px;

    > div {
      width: 30%;
    }
  }

  @media (max-width: 550px) {
    padding: 0px;

    > div {
      width: 45%;
    }
  }
`;

const textContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 25px;
  font-weight: 900;

  @media (max-width: 900px) {
    width: 35%;
  }
  @media (max-width: 800px) {
    align-items: flex-start;
    text-align: left;
  }
  @media (max-width: 600px) {
    width: 45%;
  }
  @media (max-width: 365px) {
    width: 45%;
  }
`;

const iconClass = css`
  height: 50px;
  pointer-events: none;

  @media (max-width: 445px) {
    height: 40px;
  }
`;

export default function AlarmMonitoringOverview() {
  return (
    <section className={sectionContainer}>
      <div className={contentContainer}>
        <div className={titleContainer}>
          <SlideInContainer>
            <TitleMed className={titleClass}>
              Alarm Monitoring Overview
            </TitleMed>
          </SlideInContainer>
        </div>
        <SlideInContainer>
          <div className={imageContainer}>
            <div className={textContainer}>
              <div className={iconClass}>
                <StaticImage
                  src="../common/icons/headset.png"
                  placeholder="blurred"
                  alt="Headset"
                  style={{ width: "54px" }}
                />
              </div>
              <TitleSmall>
                24/7 Professional
                <br />
                Monitoring
              </TitleSmall>
            </div>
            <div className={textContainer}>
              <div className={iconClass}>
                <StaticImage
                  src="../common/icons/time.png"
                  placeholder="blurred"
                  alt="Clock"
                  style={{ width: "55px" }}
                />
              </div>
              <TitleSmall>
                Real-Time Video
                <br />
                Verification
              </TitleSmall>
            </div>
            <div className={textContainer}>
              <div className={iconClass}>
                <StaticImage
                  src="../common/icons/phone.png"
                  placeholder="blurred"
                  alt="Phone"
                  style={{ width: "57px" }}
                />
              </div>
              <TitleSmall>
                Automated
                <br />
                Emergency Dispatch
              </TitleSmall>
            </div>
            <div className={textContainer}>
              <div className={iconClass}>
                <StaticImage
                  src="../common/icons/network.png"
                  placeholder="blurred"
                  alt="Network"
                  style={{ width: "57px" }}
                />
              </div>
              <TitleSmall>
                Works Across
                <br />
                Rhombus Platform
              </TitleSmall>
            </div>
            <div className={textContainer}>
              <div className={iconClass}>
                <StaticImage
                  src="../common/icons/setup.png"
                  placeholder="blurred"
                  alt="Setup"
                  style={{ width: "56px" }}
                />
              </div>
              <TitleSmall>
                Simple Setup
                <br />
                and Installation
              </TitleSmall>
            </div>
          </div>
        </SlideInContainer>
      </div>
    </section>
  );
}
