import React from "react";
import { Helmet } from "react-helmet";
import GlobalLayout from "components/page/GlobalLayout";
import AlarmMonitoringPage from "components/alarms/updated/AlarmMonitoringPage";

export default function AlarmMonitoring() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>
          Smart Commercial Alarm Monitoring Systems with Live Agent Support |
          Rhombus
        </title>
        <meta
          name="description"
          content="Rhombus offers robust, smart commercial alarm monitoring with AI threat detection, real-time verification, and rapid response. Request a demo today."
        />
      </Helmet>
      <AlarmMonitoringPage />
    </GlobalLayout>
  );
}
